<script setup lang="ts">
import { defineAsyncComponent, PropType } from 'vue';
import { ITitleBlockShort } from '@/components/TitleBlockShort.vue';
import { filterAndSortByOrderElements } from '@/helpers/allData';
import { data as allPages } from '@/store/all.data';

// Async imports for components and utilities
const SliderSwiper = defineAsyncComponent(() => import('UiKit/components/common/SliderSwiper/SliderSwiper.vue'));
const SwiperSlide = defineAsyncComponent(() => import('swiper/vue').then(module => module.SwiperSlide));
const CardWithBlueTitleLine = defineAsyncComponent(() => import('@/components/CardWithBlueTitleLine.vue').then(module => module.default));


defineProps({
  data: Array as PropType<ICardWithBlueTitleLine[]>,
  dataTitle: {
    type: Object as PropType<ITitleBlockShort>,
    required: true,
  },
  topic: {
    type: String,
    required: true,
  },
});


const getStarted = filterAndSortByOrderElements(allPages, 'slug', 'get-started');
</script>

<template>
  <section class="DigitalEcosystemSlider digital-ecosystem-slider">
    <div class="is--container">
      <div class="is--short-block">
        <h2
          v-if="dataTitle?.title"
        >
          {{ dataTitle?.title }}
        </h2>
        <p
          v-if="dataTitle?.subtitle"
          class="is--subheading-1"
        >
          {{ dataTitle?.subtitle }}
        </p>
        <a
          v-if="dataTitle?.link"
          class="is--link-1"
          tag="a"
          :href="`${getStarted[0].url}?topic=${topic}`"
        >
          {{ dataTitle?.linkText }}
        </a>
      </div>
      <SliderSwiper
        :data="data"
      >
        <SwiperSlide
          v-for="(slide, i) in data"
          :key="i"
        >
          <CardWithBlueTitleLine
            :data="slide"
          />
        </SwiperSlide>
      </SliderSwiper>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.digital-ecosystem-slider {
  overflow: hidden;
  width: 100%;
}
</style>
