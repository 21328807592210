
import type { Theme } from 'vitepress'
import 'UiKit/styles/index.scss'
import AppLayoutDefault from '@/layouts/AppLayoutDefault.vue';
import { createPinia } from 'pinia'
import VueNotification from '@kyvg/vue3-notification';
import BaseTooltip from 'UiKit/components/BaseTooltip/BaseTooltip.vue';
import BaseButton from 'UiKit/components/BaseButton/BaseButton.vue';
import BaseSvgIcon from 'UiKit/components/BaseSvgIcon/BaseSvgIcon.vue';
import BaseTag from 'UiKit/components/BaseTag/BaseTag.vue';
import TheHighlight from '@/components/TheHighlight.vue';
import CardChatWithAssistant from '@/components/CardChatWithAssistant.vue';
import CaseStudiesMain from '@/layouts/CaseStudies/components/CaseStudiesMain.vue';
import DigitalEcosystemSlider from '@/layouts/DigitalEcosystem/components/DigitalEcosystemSlider.vue';
import WeHelp from '@/markdown/markdown-we-help/WeHelp.vue';
import CheckmarkedItem from 'UiKit/components/common/CheckmarkedItem/CheckmarkedItem.vue';

export default {
  Layout: AppLayoutDefault,
  enhanceApp({ app, router, siteData }) {
    const pinia = createPinia();
    app.use(pinia)
    app.use(VueNotification)
    app.component('BaseTooltip', BaseTooltip)
    app.component('BaseButton', BaseButton)
    app.component('BaseSvgIcon', BaseSvgIcon)
    app.component('BaseTag', BaseTag)
    app.component('TheHighlight', TheHighlight)
    app.component('CardChatWithAssistant', CardChatWithAssistant)
    app.component('CaseStudiesMain', CaseStudiesMain)
    app.component('DigitalEcosystemSlider', DigitalEcosystemSlider)
    app.component('WeHelp', WeHelp)
    app.component('CheckmarkedItem', CheckmarkedItem)
  }
} satisfies Theme
