

export const ResourceCenterTagItems = [
  'Project Management',
  'Fintech',
  'Python',
  'Golang',
  'Vuejs 2',
  'CTO as a service',
  'Machine Learning',
  'E-commerce',
  'Vue',
  'Network Security',
  'UX/UI',
];

export const env = {
  env: import.meta.env?.VITE_ENV,
  robotsNoIndex: import.meta.env?.VITE_robotsNoIndex,
  FRONTEND_URL: import.meta.env?.VITE_FRONTEND_URL,
  CHATBOT_URL: import.meta.env?.VITE_CHATBOT_URL,
  HUBSPOTFORM: import.meta.env?.VITE_HUBSPOTFORM,
  HUBSPOTPORTAL_ID: import.meta.env?.VITE_HUBSPOTPORTAL_ID,
  HUBSPOT_FORM_ID_RECEIVE_LATEST_NEWS: import.meta.env?.VITE_HUBSPOT_FORM_ID_RECEIVE_LATEST_NEWS,
  SEGMENT_KEY: import.meta.env?.VITE_SEGMENT_KEY,
  GTM: import.meta.env?.VITE_GTM,
  title: "Webdevelop.PRO",
  description: "Accelerate project launches with our pre-engineered components and expertise.",
  keywords: ['development', 'web development'],
  author: 'Webdevelop.pro',
  email: 'manager@webdevelop.pro',
  phone: '+1 609 733 7724',
  bookACall: import.meta.env?.VITE_bookACall,
  sendAMessage: import.meta.env?.VITE_sendAMessage,
  leaveEmail: import.meta.env?.VITE_leaveEmail,
  locale: 'en_US',
};;
