<script setup lang="ts">
import WEbDevelop from '/images/illustration-webdevelop.svg?url';
import BaseButton from 'UiKit/components/BaseButton/BaseButton.vue';
import { filterAndSortByOrderElements } from '@/helpers/allData';
import { data as allPages } from '@/store/all.data';

defineProps({
  topic: String,
});
const getStarted = filterAndSortByOrderElements(allPages, 'slug', 'get-started');
</script>

<template>
  <div class="CardChatWithAssistant card-chat-with-assistant">
    <div class="is--container">
      <div class="card-chat-with-assistant__container">
        <div class="card-chat-with-assistant__content">
          <h2 class="card-chat-with-assistant__title">
            Have more questions about this product?
          </h2>
          <p class="card-chat-with-assistant__subtitle is--subheading-2">
            Get in touch with us via our AI assistant for swift answers to all your questions.
          </p>
          <BaseButton
            tag="a"
            :href="`${getStarted[0].url}?topic=${topic}`"
            size="large"
            class="card-chat-with-assistant__button"
          >
            Chat with Assistant
          </BaseButton>
        </div>
        <div class="card-chat-with-assistant__img">
          <img
            :src="WEbDevelop"
            loading="lazy"
            alt="web-develop"
            class="card-chat-with-assistant__img-icon is--no-margin"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card-chat-with-assistant {

  &__container {
    display: flex;
    width: 100%;
    padding: 130px 68px 130px 196px;
    align-items: center;
    gap: 58px;
    justify-content: space-between;
    border-radius: 2px;
    border: 1px solid $gray-20;
    background: $primary-light;
    @include media-lte(tablet) {
      padding: 40px;
      flex-direction: column;
    }
  }

  &__button {
    @include media-lte(tablet) {
      margin-top: 24px !important;
    }
  }

  &__subtitle {
    margin-top: 8px !important;
    @include media-lte(tablet) {
      margin-top: 3px !important;
    }
  }

  &__img {
    max-width: 345px;
    width: 100%;
  }

  &__img-icon {
    width: 100%;
  }
}
</style>
