import { computed, nextTick, onMounted, Ref, ref, watch } from 'vue';
import { useResizeObserver, useWindowScroll } from '@vueuse/core';


export function useSticky(
  sideSticky: Ref<HTMLElement | null>,
  mainSticky: Ref<HTMLElement | null>,
) {
  const { y } = useWindowScroll();
  const defaultMainHeight = ref();
  const isSticky = ref(false);
  const savedBlockTop = ref(0);
  let mainTopLocal = mainSticky.value?.getBoundingClientRect().top;

  const mainTop = computed(() => (mainTopLocal || 0));
  const mainHeight = computed(() => (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    defaultMainHeight.value ? defaultMainHeight.value : (sideSticky.value?.offsetHeight || 0)));
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  const mainBottom = computed(() => mainTop.value + mainHeight.value);
  const blockHeight = computed(() => sideSticky.value?.offsetHeight || 0);
  const blockTop = computed(() => sideSticky.value?.scrollTop || 0);
  const blockBottom = computed(() => blockTop.value + blockHeight.value);

  const onScroll = () => {
    if (((window.scrollY + 123) >= (mainTop.value || 0))
        && ((window.scrollY + blockBottom.value) <= (mainBottom.value - 123))) {
      isSticky.value = true;
      savedBlockTop.value = 123;
    } else {
      isSticky.value = false;
      savedBlockTop.value = 0;
      // If the bottom of the side block reaches bottomPos, make it non-sticky and save block position
      if ((window.scrollY + blockBottom.value) > (mainBottom.value - 123)) {
        savedBlockTop.value = mainHeight.value - blockHeight.value;
      }
    }
  };

  const setupObservers = () => {
    useResizeObserver(mainSticky, (entries) => {
      const entry = entries[0];
      const { height } = entry.contentRect;
      defaultMainHeight.value = height;
      onScroll();
    });
  };

  const refreshData = async () => {
    isSticky.value = false;
    savedBlockTop.value = 0;
    // Wait for DOM updates
    await nextTick();
    setupObservers();
    onScroll();
  };

  onMounted(async () => {
    setupObservers();
    await nextTick();
    mainTopLocal = mainSticky.value?.getBoundingClientRect().top;
  });

  watch(() => [window?.location?.pathname, mainSticky.value?.offsetHeight], () => {
    void refreshData();
  });

  watch(() => y.value, () => {
    onScroll();
  });

  return {
    isSticky,
    savedBlockTop,
    onScroll,
  };
}
